import React, { useEffect, useState } from 'react';
import FitmentSelectorVerifier from '../FitmentSelectorVerifier/FitmentSelectorVerifier';
import styles from './styles/ProductSelectorVerifier.scss';
import classNames from 'classnames';
import restFactory from '../../utils/restFactory';
import { ProductListResponse } from '../ProductListWrapper/models';
import { env } from '../../config';

type ProductSelectorVerifierProps = {
  productId: number | string;
  groupId: number | string;
  fitmentSelectorTitle?: string;
  qualifierSelectorTitle?: string;
  loadingQualifiersText?: string;
  autoRedirect?: boolean;
  optionNotAvailableText?: string;
  filterQualifiers?: boolean;
  resultsButtonText?: string;
};

const getProducts = async (
  productId,
  limit: number,
  page: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  qualifiers: any,
  fitment: string
) => {
  const productsResponse = await restFactory.get<ProductListResponse>(
    `${env.API_URL}/products`,
    {
      limit,
      page,
      // eslint-disable-next-line sort-keys
      fitment,
      includeSkus: true,
      productId,
      ...qualifiers,
    }
  );
  return productsResponse;
};

const ProductSelectorVerifier = ({
  groupId,
  productId,
  fitmentSelectorTitle = 'Fitment',
  qualifierSelectorTitle = 'Options',
  loadingQualifiersText = 'Loading options...',
  optionNotAvailableText = ' - Available in other combinations',
  autoRedirect = true,
  filterQualifiers = false,
  resultsButtonText = 'See results',
}: ProductSelectorVerifierProps) => {
  const [qualifiers, setQualifiers] = useState({});
  const [fitment, setFitment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingAqs, setLoadingAqs] = useState(false);

  const setAqs = (quals, isInitial = false) => {
    const newQualifiers = { ...qualifiers };
    if (isInitial) {
      for (const label of quals) {
        newQualifiers[label.id] = {};
        newQualifiers[label.id]['label'] = label.name;
        newQualifiers[label.id]['options'] = label.values.map((item) => {
          return { ...item, unavailable: false };
        });
        newQualifiers[label.id]['selected'] = '';
        newQualifiers[label.id]['unavailable'] = false;
      }
      setQualifiers(newQualifiers);
    } else if (filterQualifiers) {
      for (const label in newQualifiers) {
        newQualifiers[label]['unavailable'] = true;
        for (const newQual of quals) {
          if (label === newQual.id) {
            newQualifiers[label]['unavailable'] = false;
            for (const option of newQualifiers[label]['options']) {
              option['unavailable'] = true;
              for (const newOption of newQual.values) {
                if (newOption.id === option.id) {
                  option['unavailable'] = false;
                }
              }
            }
          }
        }
      }
      setQualifiers(newQualifiers);
    }
  };
  const initialGetAQs = async () => {
    setLoadingAqs(true);
    const res = await getProducts(productId, 1, 1, {}, fitment);
    if (autoRedirect && res.total === 1) {
      const item = res.list[0];
      window.location.href = item.url;
      return;
    }
    setAqs(res.vehicleQualifiers, true);
    window.dispatchEvent(
      new CustomEvent('getResults', {
        detail: {
          fitment,
          qualifiers: {},
        },
      })
    );
    setLoadingAqs(false);
  };

  useEffect(() => {
    if (fitment) {
      initialGetAQs();
    }
  }, [fitment]);

  const handleChange = async (labelId, value) => {
    window.dispatchEvent(new Event('qualifiersChanging'));
    const newQualifiers = { ...qualifiers };
    // if selected an unavailable option, reset all values

    newQualifiers[labelId]['selected'] = value;
    setQualifiers(newQualifiers); // update select form value
    setLoading(true);
    const params = {};
    for (const labelId of Object.keys(newQualifiers)) {
      if (newQualifiers[labelId].selected !== '') {
        params[`vq[${labelId}]`] = newQualifiers[labelId].selected;
      }
    }
    const res = await getProducts(productId, 1, 1, params, fitment);
    setAqs(res.vehicleQualifiers);
    if (autoRedirect && res.total === 1) {
      const item = res.list[0];
      window.location.href = item.url;
    } else {
      window.dispatchEvent(
        new CustomEvent('getResults', {
          detail: {
            fitment,
            qualifiers: params,
          },
        })
      );
    }
    setLoading(false);
  };
  const onSeeResults = () => {
    const resultsDiv = document.querySelector('#pl-sku-list');
    if (resultsDiv) {
      resultsDiv.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <FitmentSelectorVerifier
        orientation="vertical"
        autocommit
        groupId={groupId}
        includeSkus={true}
        styled
        productId={productId}
        title={fitmentSelectorTitle}
        onSuccess={(fitment) => {
          setFitment(fitment);
        }}
        showFieldLabels
        onChange={() => {
          setFitment(null);
        }}
      />
      {fitment && (
        <div className={classNames(styles.qualifiers)}>
          <div className={styles.qualifierTitle}>{qualifierSelectorTitle}</div>
          {loadingAqs ? (
            <div className={styles.qualifiersContainer}>
              {loadingQualifiersText}
            </div>
          ) : (
            <div className={styles.qualifiersContainer}>
              {Object.keys(qualifiers).map((label, index) => {
                return (
                  <select
                    className={
                      classNames('SuiSelect', styles.select) +
                      ` ${qualifiers[label].unavailable && styles.notAvailable}`
                    }
                    key={index}
                    value={qualifiers[label].selected}
                    onChange={(e) => handleChange(label, e.target.value)}
                    disabled={loading}
                  >
                    <option value={''}>
                      {qualifiers[label].label}
                      {qualifiers[label].unavailable && optionNotAvailableText}
                    </option>
                    {qualifiers[label] &&
                      qualifiers[label].options.map((item) => {
                        return (
                          <option
                            key={item.id}
                            value={item.id}
                            className={
                              (item.unavailable ||
                                qualifiers[label].unavailable) &&
                              styles.notAvailable
                            }
                          >
                            {item.name}
                            {(item.unavailable ||
                              qualifiers[label].unavailable) &&
                              optionNotAvailableText}
                          </option>
                        );
                      })}
                  </select>
                );
              })}
              <button
                type="button"
                onClick={onSeeResults}
                className={styles.resultsButton}
              >
                {resultsButtonText}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductSelectorVerifier;
