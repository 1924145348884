import * as React from 'react';
import {
  ChatContainer,
  ConversationHeader,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
  TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import CrossIcon from '../../icons/CrossIcon';
// eslint-disable-next-line import/no-named-as-default
import io from 'socket.io-client';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles/chatbot.scss';

const disableAiChat = new CustomEvent('disableAiChat', {
  bubbles: true,
  cancelable: true,
  composed: false,
  detail: { enableChat: false },
});
const CHAT_HISTORY_KEY = 'chatHistory';

const socket = io(process.env.API_URL, { autoConnect: true });

const Chatbot = () => {
  const [isTyping, setIsTyping] = React.useState(false);
  const [, setIsConnected] = React.useState(false);

  const handleDisableAiChat = () => {
    document.dispatchEvent(disableAiChat);
  };
  const gptResponse = (data: any) => {
    setMessages((messages) => [
      ...messages,
      {
        content: data.msg,
        direction: 'incoming' as any,
        position: 'normal',
        role: 'Chat',
        type: 'just now',
      },
    ]);
    setIsTyping(false);
  };

  React.useEffect(() => {
    socket.on('connect', () => {
      setIsConnected(true);
    });
    socket.on('gptResponse', gptResponse);
    socket.on('error', (data) => {
      setMessages((messages) => [...messages, data]);
    });

    return () => {
      socket.off('connect');
      socket.off('gptResponse');
      socket.off('error');
      socket.off('typingIndicator');
    };
  }, []);

  const [messages, setMessages] = React.useState(() => {
    const savedMessages = localStorage.getItem(CHAT_HISTORY_KEY);
    return savedMessages ? JSON.parse(savedMessages) : [];
  });

  const handleSend = async (newMessage) => {
    const messageToSend = {
      content: newMessage,
      direction: 'outgoing' as any,
      position: 'normal',
      role: 'User',
      type: 'just now',
    };

    const newMessages = [...messages, messageToSend];
    socket.emit('ai_search', { prompt: newMessage });
    saveMessagesToLocalStorage(messages);
    setIsTyping(true);
    setMessages(newMessages);
  };
  const saveMessagesToLocalStorage = (messages) => {
    localStorage.setItem(CHAT_HISTORY_KEY, JSON.stringify(messages));
  };

  return (
    <div className={styles.chatContainer}>
      <MainContainer className={styles.csMainContainer}>
        <ChatContainer>
          <ConversationHeader>
            <ConversationHeader.Content className={styles.csHeaderContainer}>
              <span>Parts Logic AI Assistant</span>
              <button
                className={styles.closeBtn}
                role="button"
                aria-label="close"
                onClick={handleDisableAiChat}
              >
                <CrossIcon size={30} />
              </button>
            </ConversationHeader.Content>
          </ConversationHeader>
          <MessageList typingIndicator={isTyping && <TypingIndicator />}>
            {messages.map((message, i) => {
              return (
                <Message
                  key={i}
                  model={{
                    direction: message.direction as any,
                    message: message.content,
                    position: message.position as any,
                    sender: 'User',
                    sentTime: 'just now',
                  }}
                ></Message>
              );
            })}
          </MessageList>
          <MessageInput
            className={styles.csMessageInput}
            attachButton={false}
            placeholder="Type message here"
            onSend={(message) => handleSend(message)}
          />
        </ChatContainer>
      </MainContainer>
    </div>
  );
};

export default Chatbot;
