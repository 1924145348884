import React, { useEffect, useState } from 'react';
import s from './style.scss';
import FacetFilter from '../FacetFilter';
import loadash, { isEmpty } from 'lodash';
import BreadCrumb from '../BreadCrumb';
import WsmFitmentSelectorWrapper from '../WsmFitmentSelectorWrapper';
import WsmProductListWrapper from '../WsmProductListWrapper';
import { updateFacetQueryParams } from '../../utils/fitmentUtils';
import {
  FACET_PREFIX,
  getInitialFitment,
  getProductQuery,
  getSearchParams,
  updateUrl,
} from '../WsmSearchPage/utils';

const getQueryParam = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('q');
};
const clearSearchParam = () => {
  const params = new URLSearchParams(window.location.search);
  params.delete('q');
  window.history.pushState({}, '', location.pathname + `?${params.toString()}`);
};
const getFacetKeysFromUrl = (queryString: URLSearchParams) => {
  const fitmentQuery = [];
  for (const key of Array.from(queryString.keys())) {
    if (key.includes(FACET_PREFIX)) {
      fitmentQuery.push(key);
    }
  }
  return fitmentQuery;
};
const clearVqParams = () => {
  const params = new URLSearchParams(window.location.search);
  params.forEach((value, key) => {
    if (key.includes('vq[')) {
      params.delete(key);
    }
  });
  window.history.pushState({}, '', location.pathname + `?${params.toString()}`);
};
type InitialFacets = {
  [name: string]: string[];
};
type Props = {
  /** Id of the fitment group your site uses for fitment.*/
  groupId: number;
  /** Will determine the search used for the results */
  searchString?: string;
  /** {"facet": ["facetValue"]} object, will condition search with an initial facet selection. Value is an array* */
  initialFacets?: InitialFacets;
  /** If set, will display above the fitment selector.*/
  fitmentSelectorTitle?: string;
  /** Label of the search button under the fitment selector. Has a default value.*/
  fitmentSelectorSearchButton?: string;
  /** Label of the clear button under the fitment selector. Has a default value.*/
  fitmentSelectorClearButton?: string;
  /** Text to show when no results are returned. Has a default value.*/
  noResultsText?: string;
  /** Text to show above the qualifiers section. Has a default value.*/
  optionalLabelsTitle?: string;
  /** Determines if optional qualifiers will be used or not. Defaults to "enabled".*/
  qualifiersStatus?: 'enabled' | 'disabled';
  /** If set to true, items will display the discount percentage badge */
  showBadge?: boolean;
};
const ResultsPage = ({
  /* Description */
  groupId,
  initialFacets = {},
  fitmentSelectorSearchButton = 'Search',
  fitmentSelectorClearButton = 'Clear',
  noResultsText,
  fitmentSelectorTitle,
  optionalLabelsTitle,
  qualifiersStatus = 'enabled',
  showBadge = true,
}: Props) => {
  const queryString = new URLSearchParams(window.location.search);
  const [searchParams, setSearchParams] = useState<any>(
    getSearchParams(new URLSearchParams(window.location.search))
  );
  const [facets, setFacets] = useState<any>({
    ...initialFacets,
    ...getFacetKeysFromUrl(queryString).reduce((acc, facet) => {
      const values = queryString.getAll(facet);
      if (values.length) {
        return {
          ...acc,
          [facet.replace(FACET_PREFIX, '')]: queryString.getAll(facet),
        };
      }
      return acc;
    }, {}),
  });
  const [facetOptions, setFacetOptions] = useState<any>({});
  const [search, setSearch] = useState<string>(getQueryParam() || '');
  const [selectedFitmentQuery, setSelectedFitmentQuery] = React.useState(
    getInitialFitment(queryString)
  );
  const [qualifiers, setQualifiers] = useState({});
  const [productsQuery, setProductsQuery] = React.useState(
    getProductQuery(facets, selectedFitmentQuery, groupId, queryString)
  );

  // Updates fitment when changed from another component
  useEffect(() => {
    const updateFitment = (e: CustomEvent) => {
      const newFitment = e.detail.fitment;
      setSelectedFitmentQuery(Object.values(newFitment).join('|'));
      clearVqParams();
    };
    window.addEventListener('PL_FITMENT_CHANGED', updateFitment);

    return () => {
      window.removeEventListener('PL_FITMENT_CHANGED', updateFitment);
    };
  }, []);

  useEffect(() => {
    // We don't want to update the product query object on first render becasue was already
    // set on the useState function, so we wait for a change on selectedFacets or selectedFitmentQuery
    const query = getProductQuery(
      facets,
      selectedFitmentQuery,
      groupId,
      queryString
    );
    setProductsQuery(query);
  }, [facets, selectedFitmentQuery, qualifiers]);

  return (
    <div className={s['results-page-container']}>
      <div className={s['top-section']}>
        <BreadCrumb
          filters={
            search
              ? [
                  {
                    color: '#f8f8f8',
                    id: 1,
                    label: `Keyword: ${search}`,
                  },
                ]
              : []
          }
          onRemoveFilter={() => {
            setSearch('');
            clearSearchParam();
          }}
          paths={{
            list: [
              {
                label: 'Home',
                url: '/',
              },
              {
                label: 'Search',
                url: window.location.pathname,
              },
            ],
            separator: '/',
          }}
        />
      </div>
      <div className={s['bottom-section']}>
        <div className={s['left-column']}>
          <div
            className={`${s['fitment-selector-container']} pl-fitment-selector-section`}
          >
            {fitmentSelectorTitle && (
              <div className={`${s['title']} pl-fitment-selector-title`}>
                {fitmentSelectorTitle}
              </div>
            )}
            <WsmFitmentSelectorWrapper
              groupId={groupId}
              orientation="vertical"
              styled
              className={s['fitment-selector']}
              onSubmit={(ftm, opts) => {
                if (opts && !isEmpty(opts)) {
                  const params = new URLSearchParams(window.location.search);
                  // set selected vqs in url
                  for (const vqName in opts) {
                    params.set(`vq[${vqName}]`, opts[vqName]);
                  }
                  // clean vqs that were deselected
                  params.forEach((value, key) => {
                    if (key.includes('vq[')) {
                      const prop = key.replace('vq[', '').replace(']', '');
                      if (!opts[prop]) {
                        params.delete(key);
                      }
                    }
                  });
                  window.history.pushState(
                    {},
                    '',
                    window.location.pathname + `?${params.toString()}`
                  );
                  setQualifiers(opts);
                }
                return ftm;
              }}
              onClearCb={() => {
                return;
              }}
              qualifiersStatus={qualifiersStatus}
              searchButtonText={fitmentSelectorSearchButton}
              clearButtonText={fitmentSelectorClearButton}
              optionalLabelsTitle={optionalLabelsTitle}
              loadingQualifiersText=""
            />
          </div>
          <FacetFilter
            enableCollapse
            styled
            onChange={(e) => {
              if (!loadash.isEqual(e.selectedValues, facets)) {
                updateFacetQueryParams(e.selectedValues);
                setFacets(e.selectedValues);
              }
            }}
            selectedValues={facets}
            data={facetOptions}
          />
        </div>
        <div className={s['results-container']}>
          {
            <WsmProductListWrapper
              styled
              showPagination
              showControls
              showLoadingIndicator
              showBadge={showBadge}
              renderNoResults={
                noResultsText
                  ? () => (
                      <h4 style={{ textAlign: 'center' }}>{noResultsText}</h4>
                    )
                  : undefined
              }
              onDataReceived={(e) => {
                // When results come in, we update the facets available, but only if they changed
                const parsedFacets = {};
                e.facets.forEach((item) => {
                  const facet = {
                    title: item.name,
                    values: item.values,
                  };
                  parsedFacets[item.name] = facet;
                });
                if (!loadash.isEqual(facetOptions, parsedFacets)) {
                  setFacetOptions(parsedFacets);
                }
              }}
              onPageChanged={(page) => {
                setSearchParams({ ...searchParams, page });
                setTimeout(() => {
                  window.scrollTo({ behavior: 'smooth', top: 0 });
                }, 1);
                if (page !== 1) {
                  updateUrl('page', page);
                } else updateUrl('page', null);
              }}
              itemsPerPage={searchParams.limit}
              onItemsPerPageChange={(value) => {
                setSearchParams({ ...searchParams, limit: value });
                updateUrl('limit', value);
              }}
              currentPage={searchParams.page}
              search={searchParams.q || ''}
              selectedSort={searchParams.sort}
              onSortChange={(value) => {
                setSearchParams({ ...searchParams, sort: value });
                updateUrl('sort', value);
              }}
              query={productsQuery}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default ResultsPage;
