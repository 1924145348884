import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import * as React from 'react';
import useEventCallback from '../../common/customHooks/useEventCallback';
import { env } from '../../config';
import { Error } from '../../models/generic';
import {
  DYN_SELECTED_VQ,
  DYN_SELECTED_VQ_REALID,
  FITMENT_SELECTOR_STORAGE_KEY,
} from '../../utils/constants';
import restFactory from '../../utils/restFactory';
import FitmentSelector from '../FitmentSelector/FitmentSelector';
import {
  FitmentDataItem,
  FitmentSelectorProps,
  FitmentLabelEntity,
  SelectedValues,
} from '../FitmentSelector/models';
import { ProductListResponse } from '../ProductListWrapper/models';
import { transformOptionalLabelsAndData } from '../WsmSearchPage/utils';
import { FitmentSelectorWrapperProps } from './models';
import styles from './styles/fitmentSelectorWrapper.scss';
import { isEmpty } from 'lodash';
import { updateFitmentQueryParams } from '../../utils/fitmentUtils';
/*
 When using jest.runAllTimers() we get the error:
 Ran 100000 timers, and there are still more! Assuming we've hit an infinite recursion and bailing out...

 After tying different ways to fix that, the only solution that worked was using jest.runOnlyPendingTimers();
 and setting the delay to 0 in the test env.
*/
const LOAD_DATA_DELAY = process.env.NODE_ENV.localeCompare('test') ? 150 : 0;

/*
  When the Fitment selector is used to show information related to one product,
  optional labels are not shown because we need all product information.
  Optional labels are only needed when user is in the landing or product list page to filter out
  products in a more generic way.

  A filter must be passed to this component if we want to show data for a specific product
*/
export const PRODUCT_KEY = 'product';

const FitmentSelectorWrapper = ({
  onQualifiersChange = null,
  autocommit,
  autocommitDelay = 2000,
  className = '',
  clearButtonText = 'Clear',
  components,
  filter = {},
  groupId,
  id,
  orientation = 'horizontal',
  onChange,
  onDataLoaded,
  onSubmit,
  onClearCb,
  searchButtonText = 'Search',
  selectedValues: initailSelectedValues = null,
  styled = false,
  optionalLabels: initialOptionalLabels,
  optionalLabelsData: initialOptionalLabelsData,
  onError,
  qualifiersStatus = 'disabled',
  selectedFacets = {},
  optionalLabelsTitle = 'Add optional details:',
  fitmentRuleId,
  showFieldLabels,
  productId,
  sku,
  isModalOpen,
  fitmentLoaded = false,
  loadingQualifiersText = 'Loading optional fields...',
}: FitmentSelectorWrapperProps) => {
  const [error, setError] = React.useState<Error>({ message: '' });
  const [loadingVq, setLoadingVq] = React.useState<boolean>(false);
  const [labels, setLabels] = React.useState<FitmentLabelEntity[]>([]);

  const [optionalLabels, setOptionalLabels] = React.useState<
    FitmentLabelEntity[]
  >([]);

  const [labelsData, setLabelsData] = React.useState<
    FitmentSelectorProps['labelsData']
  >({});
  const [optionalLabelsData, setOptionalLabelsData] = React.useState<
    FitmentSelectorProps['optionalLabelsData']
  >({});
  const [selectedValues, setSelectedValues] = React.useState<
    FitmentSelectorProps['selectedValues']
  >(initailSelectedValues);

  const [internalFitment, setInternalFitment] = React.useState<string>('');
  const loadingOptionalLabels = React.useRef(false);
  const loadVqs = async (fitmentValues) => {
    if (isEmpty(fitmentValues)) {
      return;
    }
    setLoadingVq(true);
    setOptionalLabels([]);
    setOptionalLabelsData({});
    const fitment = Object.keys(fitmentValues)
      .reduce((acc, curr) => {
        return [...acc, fitmentValues[curr]];
      }, [])
      .join('|');
    setInternalFitment(fitment);

    /**
     * Add selected facets on products query
     */
    let accFactets = {};
    Object.keys(selectedFacets || {}).forEach((item) => {
      selectedFacets[item]?.forEach((i) => {
        accFactets = { ...accFactets, [`${item}`]: i };
      });
    });
    const options = {
      limit: 1,
      page: 1,
      // eslint-disable-next-line sort-keys
      fitment,
      productId,
      ...accFactets,
      sku,
    };
    const productsResponse = await restFactory.get<ProductListResponse>(
      `${env.API_URL}/products`,
      options
    );

    const [optionalLabels, optionalData] = transformOptionalLabelsAndData(
      productsResponse?.vehicleQualifiers
    );
    if (onQualifiersChange) {
      onQualifiersChange({ optionalData, optionalLabels });
    }
    setOptionalLabels(optionalLabels);
    setOptionalLabelsData(optionalData);
    setLoadingVq(false);
  };
  React.useEffect(() => {
    setSelectedValues(initailSelectedValues);
  }, [initailSelectedValues]);

  React.useEffect(() => {
    if (initialOptionalLabels?.length > 0) {
      setOptionalLabels(initialOptionalLabels);
    }
    if (Object.keys(initialOptionalLabelsData || {})?.length > 0) {
      setOptionalLabelsData(initialOptionalLabelsData);
    }
  }, [initialOptionalLabels, initialOptionalLabelsData]);

  React.useEffect(() => {
    let isMounted = true;
    async function doCall() {
      const labelsResponse = await loadLabels(
        'fitment/labels',
        groupId,
        filter,
        setError,
        productId
      );
      isMounted && setLabels(labelsResponse);

      const lastSelectedLabelIndex = Object.keys(
        initailSelectedValues || {}
      ).length;

      const labelData = await loadLabelsData(
        labelsResponse,
        lastSelectedLabelIndex,
        initailSelectedValues,
        groupId,
        filter,
        setError,
        productId
      );

      if (isMounted) {
        setLabelsData(labelData);
      }
    }
    doCall();

    return () => {
      isMounted = false;
    };
  }, []);

  React.useEffect(() => {
    if (fitmentLoaded && qualifiersStatus === 'enabled') {
      loadVqs(selectedValues);
    }
  }, [fitmentLoaded]);

  const debouncedDataLoaded = React.useCallback(
    debounce((labels, optionalLabels, labelsData) => {
      onDataLoaded(labels, optionalLabels, labelsData);
    }, LOAD_DATA_DELAY /* Magic number to don't call onDataLoaded multiple times and call with the last labels data */),
    []
  );

  React.useEffect(() => {
    if (onDataLoaded && (labels.length || Object.keys(labelsData).length)) {
      debouncedDataLoaded.cancel();
      debouncedDataLoaded(labels, optionalLabels, labelsData);
    }
  }, [labels, labelsData]);

  React.useEffect(() => {
    if (onError && error.message) {
      onError(error);
    }
  }, [error]);
  React.useEffect(() => {
    const updateFitment = (event: CustomEvent) => {
      if (!isEqual(event.detail.fitment, selectedValues)) {
        const fitmentLabelsData = event.detail.labelsData;
        if (fitmentLabelsData) {
          setLabelsData(fitmentLabelsData);
        }
        setSelectedValues(event.detail.fitment);
      }
    };
    window.addEventListener('PL_FITMENT_CHANGED', updateFitment);
    return () => {
      window.removeEventListener('PL_FITMENT_CHANGED', updateFitment);
    };
  }, []);
  // eslint-disable-next-line complexity
  const _onChange = async (
    labelId: number | string,
    values: SelectedValues
  ) => {
    if (!values && !labelId) {
      onClear();
      return;
    }
    const allLabels = [...labels /* , ...optionalLabels */];
    const nextLabelIndex =
      allLabels.findIndex((item) => item.name === labelId) + 1;
    const hasNextLabel = nextLabelIndex < allLabels.length;
    const copiedValues = {
      ...values,
      ...(hasNextLabel ? { [allLabels[nextLabelIndex].name]: undefined } : {}),
    };
    const labelToClearData = allLabels.slice(nextLabelIndex);
    for (const key of labelToClearData) {
      delete copiedValues[key.name];
    }

    setSelectedValues(copiedValues);
    let isFirstFitmentAndNovalue = false;

    if (
      Object.keys(values).length === 1 &&
      Object.values(values).find((v) => v === '') === ''
    ) {
      isFirstFitmentAndNovalue = true;
    }

    if (hasNextLabel) {
      if (!isFirstFitmentAndNovalue) {
        const nextLabelData = await loadLabelData(
          allLabels,
          nextLabelIndex,
          filter,
          groupId,
          values,
          setError,
          fitmentRuleId,
          productId
        );
        const newLabelsData = {
          ...labelsData,
          [allLabels[nextLabelIndex].name]: nextLabelData,
        };
        setLabelsData(newLabelsData);
      }
    } else if (qualifiersStatus === 'enabled') {
      loadVqs(copiedValues);
    }

    onChange?.(labelId, values);
  };

  const onClear = () => {
    onChange?.(null, null);
    setSelectedValues({});
    setLabelsData({ [labels[0].name]: labelsData[labels[0].name] });
    setOptionalLabels([]);
    const fitmentEvent = new CustomEvent('PL_FITMENT_CHANGED', {
      detail: { fitment: {} },
    });
    updateFitmentQueryParams({});
    window.dispatchEvent(fitmentEvent);
  };

  const _onOptionalLabelsChange = async (
    labelId,
    realId,
    newSelectedValues,
    newRealIdSelectedValues
  ) => {
    const savedFitment =
      JSON.parse(
        localStorage.getItem(FITMENT_SELECTOR_STORAGE_KEY) || '{}'
      )?.[0] || '';

    const vqs = {};
    Object.keys(newRealIdSelectedValues)?.forEach((v) => {
      vqs[`vq[${v}]`] = newRealIdSelectedValues[v];
    });

    localStorage.setItem(DYN_SELECTED_VQ, JSON.stringify(newSelectedValues));
    localStorage.setItem(
      DYN_SELECTED_VQ_REALID,
      JSON.stringify(newRealIdSelectedValues)
    );

    /**
     * Add selected facets on products query
     */
    let accFactets = {};
    Object.keys(selectedFacets || {}).forEach((item) => {
      selectedFacets[item]?.forEach((i) => {
        accFactets = { ...accFactets, [`${item}`]: i };
      });
    });

    setLoadingVq(true);
    setOptionalLabels([]);
    setOptionalLabelsData({});
    const productsResponse = await restFactory.get<ProductListResponse>(
      `${env.API_URL}/products`,
      {
        limit: 1,
        page: 1,
        // eslint-disable-next-line sort-keys
        fitment: savedFitment || internalFitment,
        ...vqs,
        ...accFactets,
        sku,
      }
    );

    const [optionalLabels, optionalData] = transformOptionalLabelsAndData(
      productsResponse?.vehicleQualifiers
    );

    Object.keys(newSelectedValues).forEach((v) => {
      if (!Object.keys(optionalData).includes(v)) {
        optionalData[v] = [
          { id: newSelectedValues[v], name: newSelectedValues[v] },
        ];

        Object.keys(newSelectedValues).forEach((vv, i) => {
          if (v === vv) {
            optionalLabels.push({
              id: vv,
              name: vv,
              real_id: Object.keys(newRealIdSelectedValues)[i],
            });
          }
        });
      }
    });

    setOptionalLabels(optionalLabels);
    setOptionalLabelsData(optionalData);
    setLoadingVq(false);
  };

  const onSearch = useEventCallback(
    (values: SelectedValues, optValues: SelectedValues) => {
      const allowSubmitAutocommit =
        Object.keys(selectedValues).length ===
        labels.length + optionalLabels.length;
      if (
        !loadingOptionalLabels.current &&
        ((autocommit && allowSubmitAutocommit) || !autocommit)
      ) {
        // This callback must be called only when user clicks Submit button,
        // or if autocommit is TRUE when all values are slected, including
        // optional labels
        const fitmentEvent = new CustomEvent('PL_FITMENT_CHANGED', {
          detail: { fitment: values, labelsData: labelsData },
        });
        updateFitmentQueryParams(values);
        window.dispatchEvent(fitmentEvent);
        onSubmit?.(values, optValues);
        //getExistingFitment();
      }
    },
    [optionalLabels, selectedValues]
  );

  if (!groupId) {
    console.error('Please send a groupId');
  }

  return error.message ? (
    <p className={styles.error}>{error.message}</p>
  ) : (
    <FitmentSelector
      autocommit={autocommit}
      autocommitDelay={autocommitDelay}
      className={className}
      clearButtonText={clearButtonText}
      id={id}
      orientation={orientation}
      onChange={_onChange}
      onSubmit={onSearch}
      searchButtonText={searchButtonText}
      styled={styled}
      onClearCb={onClearCb}
      components={components}
      labels={labels}
      optionalLabels={optionalLabels}
      optionalLabelsData={optionalLabelsData}
      optionalLabelsTitle={optionalLabelsTitle}
      labelsData={labelsData}
      selectedValues={selectedValues}
      isLoadingOptionalLabel={loadingVq}
      onOptionalLabelsChange={_onOptionalLabelsChange}
      showFieldLabels={showFieldLabels}
      isModalOpen={isModalOpen}
      selectedFacets={selectedFacets}
      loadingQualifiersText={loadingQualifiersText}
    />
  );
};

async function loadLabels(
  path: string,
  groupId: FitmentSelectorWrapperProps['groupId'],
  filter: FitmentSelectorWrapperProps['filter'],
  setError: React.Dispatch<React.SetStateAction<Error>>,
  productId: string | number
) {
  try {
    const options = {
      groupId,
      productId,
      ...convertKeysToLowerCase(filter),
    };
    const response = (await restFactory.get(
      `${env.API_URL}/${path}`,
      options
    )) as FitmentLabelEntity[];

    return response;
  } catch (error) {
    setError({ message: 'Error fetching list of labels' });
    return [];
  }
}

/**
 * When there are initial selected values, we need to fetch data for all selected labels plus
 * the next label data.
 * @param labels the list of all labels (dropdowns)
 * @param lastIndex Number of lables to fetch data, if inital selected labels are Year and Make, we
 * need here an index of 3 so fetch data for Year, Make and Model.
 */
async function loadLabelsData(
  labels: FitmentLabelEntity[],
  lastIndex: number,
  initailSelectedValues: FitmentSelectorWrapperProps['selectedValues'],
  groupId: FitmentSelectorWrapperProps['groupId'],
  filter: FitmentSelectorWrapperProps['filter'],
  setError: React.Dispatch<React.SetStateAction<Error>>,
  productId
): Promise<FitmentSelectorProps['labelsData']> {
  const labelsToFetchData = labels.slice(
    0,
    Math.min(labels.length, lastIndex + 1)
  );
  let labelQuery = {};
  const promises: Array<Promise<FitmentDataItem[]>> = labelsToFetchData.map(
    (item, index) => {
      const prevLabelName = index ? labelsToFetchData[index - 1].name : null;
      if (index) {
        labelQuery = {
          ...labelQuery,
          [`${prevLabelName}`]: initailSelectedValues[`${prevLabelName}`],
        };
      }
      const query = {
        ...filter,
        parents: Object.values(labelQuery || {}) as string[],
      };
      const options = {
        groupId,
        productId,
        ...query,
      };
      return restFactory.get(
        `${env.API_URL}/fitment/labels/${encodeURIComponent(item.name)}`,
        options
      );
    }
  );
  try {
    const response = await Promise.all(promises);
    const result = labelsToFetchData.reduce(
      (acc, item, index) => ({
        ...acc,
        [item.name]: response[index].map(parseLabelData),
      }),
      {}
    );
    return result;
  } catch (error) {
    setError({ message: 'Error fetching label data' });
    return {};
  }
}

async function loadLabelData(
  labels: FitmentLabelEntity[],
  labelIndex: number,
  filter: FitmentSelectorWrapperProps['filter'],
  groupId: FitmentSelectorWrapperProps['groupId'],
  prevLabelValues: FitmentSelectorWrapperProps['selectedValues'],
  setError: React.Dispatch<React.SetStateAction<Error>>,
  fitmentRuleId: FitmentSelectorWrapperProps['fitmentRuleId'],
  productId: number | string
): Promise<FitmentLabelEntity[]> {
  try {
    const options = {
      ...filter,
      fitmentRuleId,
      groupId,
      parents: Object.values(prevLabelValues),
      productId,
    };
    const response = (await restFactory.get(
      `${env.API_URL}/fitment/labels/${encodeURIComponent(
        labels[labelIndex].name
      )}`,
      options
    )) as FitmentDataItem[];
    return response.map(parseLabelData);
  } catch (error) {
    setError({ message: 'Error fetching label data' });
    return [];
  }
}

function convertKeysToLowerCase(obj: { [key: string]: string | number }) {
  return Object.entries(obj).reduce((acc, item) => {
    return { ...acc, [item[0].toLowerCase()]: item[1] };
  }, {});
}

export function parseLabelData(data: FitmentDataItem) {
  return {
    id: data.value,
    name: data.value,
    priority: data.priority,
  };
}

export default FitmentSelectorWrapper;
