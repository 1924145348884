import classnames from 'classnames';
import * as React from 'react';
import Modal from '../../common/Modal/Modal';
import WsmSearchBarWrapper from '../WsmSearchBarWrapper/WsmSearchBarWrapper';
import { WsmSearchBarWrapperModalProps } from './models';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './style.scss';

const WsmSearchBarWrapperModal = ({
  show,
  onClose,
  className,
  ...searchWrapperProps
}: WsmSearchBarWrapperModalProps) => {
  const [key, setKey] = React.useState(0);
  React.useEffect(() => {
    if (!show) {
      // We only update the key when the modal is hidden to
      // trigger a re-render and reset the value if the user
      // typed something on the input but didn't submit the form.
      setKey(key + 1);
    }
  }, [show]);
  return (
    <Modal
      key={key}
      isOpen={true}
      focus={false}
      onClose={onClose}
      className={classnames(styles.wsmSearchBarModal, className, {
        [styles.hide]: !show,
      })}
    >
      <WsmSearchBarWrapper {...searchWrapperProps}></WsmSearchBarWrapper>
    </Modal>
  );
};

export default WsmSearchBarWrapperModal;
