import classnames from 'classnames';
import * as React from 'react';
import Modal from '../../common/Modal/Modal';
import styles from './facetFilterModal.module.scss';
import { updateUrl, FACET_PREFIX } from './utils';

interface FacetFilterModalProps {
  classes: { root: string };
  isModalOpen: boolean;
  disbaleAllHtmlEl: boolean;
  showModal: (value: boolean) => void;
  renderCounter: (renderZero: boolean) => React.ReactNode | null;
  renderFacet: (isMobile: boolean) => JSX.Element | null;
  setSelectedFacets: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string[] | number[];
    }>
  >;
  totalResults: number;
  facetKeys: Array<string>;
}

const FacetFilterModal = ({
  classes,
  isModalOpen,
  disbaleAllHtmlEl,
  showModal,
  renderCounter,
  renderFacet,
  setSelectedFacets,
  totalResults,
  facetKeys,
}: FacetFilterModalProps) => {
  return (
    <Modal
      className={classnames(styles.modal, classes.root, {
        disableAll: disbaleAllHtmlEl,
      })}
      isOpen={isModalOpen}
      onClose={() => showModal(false)}
      showCloseButton={false}
    >
      <div>
        <div className={styles.modalHeader}>
          <div className={styles.modalTitleContainer}>
            <h1
              className={classnames(styles.modalTitle, 'Pl-facet-modal--title')}
            >
              Filters {renderCounter(true)}
            </h1>
            <p className={styles.modalTotalResults}>
              {new Intl.NumberFormat('en-US').format(totalResults)} Results
            </p>
          </div>
          <div className={styles.buttonsContainer}>
            <button
              className={classnames(
                styles.button,
                styles.secondary,
                'Pl-facet-modal--clear-btn'
              )}
              onClick={() => {
                setSelectedFacets({});
                facetKeys.forEach((item) =>
                  updateUrl<string>(`${FACET_PREFIX}${item}`, [])
                );
              }}
            >
              Clear
            </button>
            <button
              className={classnames(
                styles.button,
                styles.primary,
                'Pl-facet-modal--done-btn'
              )}
              onClick={() => showModal(false)}
            >
              Done
            </button>
          </div>
        </div>
        {isModalOpen ? (
          <div className={styles.modalFacetContainer}>{renderFacet(true)}</div>
        ) : null}
      </div>
    </Modal>
  );
};

export default FacetFilterModal;
