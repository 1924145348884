import * as React from 'react';
import { GenericObject } from '../../models/generic';
import { FITMENT_SELECTOR_STORAGE_KEY } from '../../utils/constants';
import SearchBar from '../SearchBar';
import WsmSearchBarWrapperModal from '../WsmSearchBarWrapperModal';
import styles from './searchBar.module.scss';

interface WsmSearchBarWithWrapperModalProps {
  onSubmit?: (value: string) => void;
  defaultQueryParams?: GenericObject;
  placeholder?: string;
  /*
   * Pass true to enable the AI chat behavior on the search bar
   */
  enableConversation: boolean;
  /** If set to true, the items will display the discount percentage badge */
  showBadge?: boolean;
}
const PLACEHOLDER_AI = 'search by keyword or part number OR press space for AI';
const PLACEHOLDER_NO__AI = 'search by keyword or part number';
const WsmSearchBarWithWrapperModal = ({
  onSubmit,
  defaultQueryParams = {},
  enableConversation = false,
  showBadge = true,
  placeholder = enableConversation ? PLACEHOLDER_AI : PLACEHOLDER_NO__AI,
}: WsmSearchBarWithWrapperModalProps) => {
  const [showModal, setShowModal] = React.useState(false);
  const urlSearchParms = new URLSearchParams(window.location.search);
  const query = urlSearchParms.get('q') || '';
  const fitment =
    urlSearchParms.get('fitment') ||
    JSON.parse(
      localStorage.getItem(FITMENT_SELECTOR_STORAGE_KEY) || '{}'
    )?.[0] ||
    '';

  function _onSubmit(data: string) {
    onSubmit?.(data);
  }
  return (
    <>
      <div
        className={styles.searchBarBtnContainer}
        onClick={() => {
          setShowModal(true);
          const modalInputs = document.querySelectorAll(
            '.Pl-WsmSearchBarWrapperModal input'
          ) as NodeListOf<HTMLInputElement>;

          modalInputs.forEach((el) => {
            el?.focus();
          });
        }}
        onKeyDown={() => null}
        role="button"
        tabIndex={0}
      >
        <SearchBar
          className={styles.searchBar}
          styled={true}
          showCloseIcon={false}
          placeholder={placeholder}
          searchValue={query}
          sections={[]}
          onSubmit={() => null}
          retainFitmentOnSubmit={true}
        />
      </div>

      <WsmSearchBarWrapperModal
        className="Pl-WsmSearchBarWrapperModal"
        defaultQueryParams={defaultQueryParams}
        styled={true}
        show={showModal}
        showCloseIcon={true}
        placeholder={placeholder}
        noResultsText="No Results found, please refine your keywords and try again..."
        searchValue={query}
        sections={[
          {
            maxResults: 5,
            title: 'Browse Categories',
            type: 'category',
          },
          {
            maxResults: 5,
            title: 'Browse Brands',
            type: 'brand',
          },
          {
            maxResults: 5,
            onViewMoreClick: () => {
              /* This is a custom logic needed only for WSM clients, so
                 in this case it's ok to use a non react way to trigger a click.
                 To do this in the react way, we will need to make a complex change
                 in Sunhamme-ui library to send the search value to this callback.
                */
              (
                document.querySelector(
                  '.Pl-WsmSearchBarWrapperModal button.Sui-SearchBar--search-button'
                ) as HTMLButtonElement
              )?.click();
            },
            title: 'Products',
            type: 'product',

            viewMoreButtonText: 'View More Products',
          },
        ]}
        onClose={() => setShowModal(false)}
        onSubmit={_onSubmit}
        fitment={fitment}
        enableConversation={enableConversation}
        showBadge={showBadge}
      />
    </>
  );
};
export default WsmSearchBarWithWrapperModal;
