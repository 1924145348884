import classnames from 'classnames';
import * as React from 'react';
import ProductListItem from '../../common/ProductListItem/ProductListItem';
import { Product } from '../../models/search';
import { wsmVisibleFieldsBuilder } from '../../utils/wsmVisibleFieldsBuilder';
import ListControls from '../ListControls';
import PaginationWithRangeInfo from '../PaginationWithRangeInfo';
import { DEFAULT_SORT_OPTIONS } from '../ProductList/ProductList';
import ProductListWrapper from '../ProductListWrapper';
import { ProductListWrapperProps } from '../ProductListWrapper/models';
import styles from './styles/SkuListWrapper.module.scss';
// eslint-disable-next-line css-modules/no-unused-class

const SkuListWrapper = ({
  productId,
  itemsPerPage = 5,
  currentPage = 1,
  onDataReceived,
  ...props
}: Omit<ProductListWrapperProps, 'visibleFields' | 'renderList'>) => {
  const [page, setPage] = React.useState(currentPage);
  const [sort, setSort] = React.useState(props.selectedSort);
  const [itemsPerpageIntenal, setItemsPerpage] = React.useState(itemsPerPage);
  const [totalItems, setTotalItems] = React.useState(0);
  const [orientation, setOrientation] = React.useState(props.orientation);
  const [show, setShow] = React.useState(false);
  const [query, setQuery] = React.useState({
    includeSkus: 'true',
    productId,
  });

  React.useEffect(() => {
    const callBack = (e) => {
      setShow(true);
      setPage(currentPage);
      setQuery({
        includeSkus: 'true',
        productId: productId,
        ...e.detail.qualifiers,
        fitment: e.detail.fitment,
      });
    };
    window.addEventListener('getResults', callBack);
    return () => {
      window.removeEventListener('getResults', callBack);
    };
  }, []);

  React.useEffect(() => {
    setOrientation(props.orientation);
  }, [props.orientation]);

  React.useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  React.useEffect(() => {
    setSort(props.selectedSort);
  }, [props.selectedSort]);

  React.useEffect(() => {
    setItemsPerpage(itemsPerPage);
  }, [itemsPerPage]);

  const renderPagnation = () => {
    return (
      <PaginationWithRangeInfo
        totalItems={totalItems}
        currentPage={page}
        onChangePage={(newPage) => {
          props.onPageChanged?.(newPage);
          setPage(newPage);
        }}
        itemsPerPage={itemsPerpageIntenal}
      />
    );
  };
  if (show) {
    return (
      <div id="pl-sku-list">
        {props.showControls ? (
          <ListControls
            styled={props.styled}
            orientation={orientation}
            onLayoutChange={(value) => {
              setOrientation(value);
              props.onLayoutChange?.(value);
            }}
            onItemsPerPageChange={(value) => {
              setItemsPerpage(value);
              props.onItemsPerPageChange?.(value);
            }}
            onSortChange={(value) => {
              setSort(value);
              props.onSortChange?.(value);
            }}
            itemsPerPage={itemsPerpageIntenal}
            sortOptions={DEFAULT_SORT_OPTIONS}
            selectedSort={sort}
          />
        ) : null}
        {renderPagnation()}
        <ProductListWrapper
          {...props}
          onLayoutChange={setOrientation}
          onDataReceived={(data) => {
            onDataReceived?.(data);
            setTotalItems(data?.total || 0);
          }}
          styled={true}
          showControls={false}
          selectedSort={sort}
          itemsPerPage={itemsPerpageIntenal}
          currentPage={page}
          showPagination={false}
          orientation={orientation}
          query={query}
          showLoadingIndicator={true}
          renderList={(data) => (
            <>
              {data.map((product) => {
                const { product: mutatedProduct, visibleFields } =
                  wsmVisibleFieldsBuilder(product as Product);
                return (
                  <ProductListItem
                    key={product.id}
                    className={classnames({
                      [styles.resultItemStyledInGrid]:
                        props.styled && props.orientation === 'vertical',
                    })}
                    data={mutatedProduct}
                    styled={true}
                    orientation={orientation}
                    visibleFields={visibleFields}
                    noImageUrl={props.noImageUrl}
                    linkEl={props.linkEl}
                    onItemClick={props.onItemClick}
                  />
                );
              })}
            </>
          )}
        />
        {renderPagnation()}
      </div>
    );
  }
  return null;
};

export default SkuListWrapper;
