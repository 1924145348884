import classnames from 'classnames';
import * as React from 'react';
import Image from '../../common/Image/Image';
import ProductListItem from '../../common/ProductListItem/ProductListItem';
import CrossIcon from '../../icons/CrossIcon';
import SearchIconLight from '../../icons/SearchIconLight';
import { BasicItem, Product } from '../../models/search';
import { clearSearchHistory } from '../../utils/searchHistory';
import {
  BasicItemProps,
  ProductItemProps,
  SearchbarSuggestionProps,
} from './models';
import styles from './styles/searchBarSuggestions.scss';

const ProductItem = ({
  linkEl,
  suggestion,
  styled,
  visibleFields,
  onItemClick,
}: ProductItemProps) => {
  return (
    <ProductListItem
      className={classnames(
        {
          [styles.styledResultItem]: styled,
        },
        'Sui-SearchBar--result-item'
      )}
      data={suggestion}
      visibleFields={visibleFields}
      styled={styled}
      orientation="vertical"
      linkEl={linkEl}
      onItemClick={(_, data) => onItemClick(data)}
    />
  );
};

const BasicItemSuggestion = ({
  linkEl,
  suggestion,
  onItemClick,
}: BasicItemProps) => {
  const Link = suggestion.url ? linkEl : 'div';
  const linkProps = suggestion.url ? { href: suggestion.url } : {};
  return (
    <Link
      key={suggestion.id}
      className={classnames(
        styles.link,
        styles.basicItemLink,
        'Sui-SearchBar--result-item'
      )}
      onClick={onItemClick}
      {...linkProps}
      role="listitem"
    >
      <div className={styles.imageContainer}>
        <Image url={suggestion.image_url} alt="categoryImage" />
      </div>
      <h1
        className={classnames(styles.basicItemTitle, 'Sui-CategoryList--title')}
      >
        {suggestion.name}
      </h1>
    </Link>
  );
};

const SearchTermsHistoryItem = ({
  suggestion,
  onClick,
}: {
  suggestion: BasicItem;
  onClick: (test: string) => void;
}) => {
  return (
    <button
      type="button"
      className={styles.searchTermHistoryItem}
      onClick={() => onClick(suggestion.name)}
    >
      <SearchIconLight className={styles.searchTermHistoryItemIcon} size={15} />
      <p>{suggestion.name}</p>
    </button>
  );
};

const SearchBarSuggestions = ({
  sections,
  styled,
  linkEl,
  onItemClick,
  renderProduct,
  onClearHistory,
  onHistoryItemClick,
}: SearchbarSuggestionProps) => {
  const shouldShowHistory = !sections.some(
    (item) => item.type !== 'history' && item.data?.total > 0
  );
  const sectonsToShow = shouldShowHistory
    ? sections
    : sections.filter((item) => item.type !== 'history');
  return (
    <div
      className={classnames(
        styles.suggestionContainer,
        { [styles.styledSuggestionContainer]: styled },
        'Sui-SearchBar--suggestions-container'
      )}
    >
      <div className={classnames({ [styles.styledList]: styled })}>
        {sectonsToShow.map((section) => {
          const listTitle = section.title;
          const suggestions = section.data?.list || [];
          const total = section.data?.total;
          const visibleFields = section.visibleFields;
          const type = section.type;
          if (!suggestions.length) {
            return null;
          }
          return (
            <div
              key={section.type}
              className={`Sui-SearchBar--section-container-${type}`}
            >
              {listTitle ? (
                <h3
                  className={classnames(
                    { [styles.styledListTitle]: styled },
                    'Sui-SearchBar--list-title'
                  )}
                >
                  {listTitle}
                </h3>
              ) : null}

              <div
                className={classnames(
                  styles.listContainer,
                  `Sui-SearchBar--section-${type}`
                )}
              >
                {suggestions.map((suggestion) => {
                  switch (type) {
                    case 'product':
                      return (
                        renderProduct?.(suggestion as Product) || (
                          <ProductItem
                            key={suggestion.id}
                            linkEl={linkEl}
                            suggestion={suggestion as Product}
                            styled={styled}
                            visibleFields={visibleFields}
                            onItemClick={() => onItemClick?.(suggestion)}
                          />
                        )
                      );

                    case 'history':
                      return (
                        <SearchTermsHistoryItem
                          key={suggestion.id}
                          suggestion={suggestion as BasicItem}
                          onClick={(text: string) => onHistoryItemClick?.(text)}
                        />
                      );

                    default:
                      return (
                        <BasicItemSuggestion
                          key={suggestion.id}
                          linkEl={linkEl}
                          suggestion={suggestion as BasicItem}
                          onItemClick={() => onItemClick?.(suggestion)}
                        />
                      );
                  }
                })}
              </div>
              {section.type === 'history' ? (
                <button
                  type="button"
                  className={styles.clearHistoryBtn}
                  onClick={() => {
                    clearSearchHistory();
                    onClearHistory?.();
                  }}
                >
                  <CrossIcon size={15} />
                  Clear History
                </button>
              ) : null}
              {section.type !== 'history' &&
              section.onViewMoreClick &&
              total > suggestions.length ? (
                <div
                  className={classnames(
                    styles.viewMoreContainer,
                    'Sui-SearchBar--section-view-more-button'
                  )}
                >
                  <button
                    type="button"
                    onClick={() => {
                      // Can't use lodash/omit because the build throws an error
                      const sectionToSend = { ...section };
                      delete sectionToSend.onViewMoreClick;
                      section.onViewMoreClick(sectionToSend);
                    }}
                    className={
                      styled
                        ? `SuiButton primary ${styles.styledViewMoreBtn}`
                        : ''
                    }
                  >
                    {section.viewMoreButtonText || 'View more'}
                  </button>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchBarSuggestions;
