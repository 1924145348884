import classNames from 'classnames';
import * as React from 'react';
import ProductListItem from '../../common/ProductListItem/ProductListItem';
import { Product } from '../../models/search';
import { addSearchTerm } from '../../utils/searchHistory';
import { wsmVisibleFieldsBuilder } from '../../utils/wsmVisibleFieldsBuilder';
import SearchBarSuggestions from '../SearchBar/SearchBarSuggestions';
import SearchBarWrapper from '../SearchBarWrapper';
import { DEFAULT_SECTION } from '../SearchBarWrapper/SearchBarWrapper';
import { WsmSearchBarWrapperProps, WsmSearchbarWrapperSection } from './models';
import styles from './wsmSearchBarWrapperStyles.scss';
import Chatbot from '../Chatbot/Chatbot';

const HISTORY_SECTION: WsmSearchbarWrapperSection = {
  maxResults: 5,
  title: '',
  type: 'history',
};

const DEFAULT_SECTIONS_WSM: WsmSearchbarWrapperSection[] = [...DEFAULT_SECTION];
const PLACEHOLDER_AI = 'search by keyword or part number OR press space for AI';
const PLACEHOLDER_NO__AI = 'search by keyword or part number';
const WsmSearchBarWrapper = ({
  showRecentTerms = true,
  sections: sectionProp = DEFAULT_SECTIONS_WSM,
  enableConversation = false,
  showBadge = true,
  ...props
}: WsmSearchBarWrapperProps) => {
  const [, reRender] = React.useState({});
  const sections = showRecentTerms
    ? [HISTORY_SECTION, ...sectionProp]
    : sectionProp;

  const searchRef = React.useRef(null);
  const [searchValue, setSearchValue] = React.useState(props.searchValue);
  const [enableChat, setEnableChat] = React.useState(false);

  React.useEffect(() => {
    addEventListener('enableAiChat', (event: any) => {
      setEnableChat(event.detail.enableChat);
    });
    addEventListener('disableAiChat', (event: any) => {
      setEnableChat(event.detail.enableChat);
    });
  });

  return (
    <div style={{ position: 'relative' }}>
      <SearchBarWrapper
        {...props}
        ref={searchRef}
        className={classNames(styles.root, props.className)}
        sections={sections}
        defaultQueryParams={props.defaultQueryParams}
        searchValue={searchValue}
        placeholder={
          props.placeholder
            ? props.placeholder
            : enableConversation
            ? PLACEHOLDER_AI
            : PLACEHOLDER_NO__AI
        }
        renderList={(data, currentSearch) => {
          return (
            <SearchBarSuggestions
              styled={props.styled}
              sections={(sections || DEFAULT_SECTIONS_WSM).map((section) => ({
                ...section,
                data: data[section.type].data,
              }))}
              linkEl={props.linkEl || 'a'}
              onItemClick={(data) => {
                addSearchTerm(currentSearch);
                props.onItemClick?.(data);
              }}
              onClearHistory={() => reRender({})}
              onHistoryItemClick={(text) => {
                setSearchValue(text);
                searchRef.current.loadData(text, props.fitment);
              }}
              renderProduct={(product: Product) => {
                const { product: mutatedProduct, visibleFields } =
                  wsmVisibleFieldsBuilder(product as Product);
                /*
                   This mutation is needed because in the search bar we don't want to show
                   the remarks data. In the visible fields logic, all product data that is a Nullish value won't
                   be displayed.
                  */
                mutatedProduct.remarks = null;
                mutatedProduct.availability_remarks = null;
                return (
                  <ProductListItem
                    key={product.id}
                    data={mutatedProduct}
                    styled={props.styled}
                    orientation={'horizontal'}
                    visibleFields={visibleFields}
                    showBadge={showBadge}
                    linkEl={props.linkEl || 'a'}
                    onItemClick={(_, data) => {
                      addSearchTerm(currentSearch);
                      props.onItemClick?.(data);
                    }}
                  />
                );
              }}
            />
          );
        }}
      />
      {enableConversation && enableChat && <Chatbot />}
    </div>
  );
};

export default WsmSearchBarWrapper;
