import { SEARCH_TERMS_HISTORY_KEY } from './constants';

export function clearSearchHistory() {
  localStorage.removeItem(SEARCH_TERMS_HISTORY_KEY);
}

export function getSearchHistory() {
  return JSON.parse(localStorage.getItem(SEARCH_TERMS_HISTORY_KEY) || '[]');
}

export function addSearchTerm(value: string) {
  if (value) {
    const savedTerms =
      JSON.parse(localStorage.getItem(SEARCH_TERMS_HISTORY_KEY)) || [];
    localStorage.setItem(
      SEARCH_TERMS_HISTORY_KEY,
      JSON.stringify(Array.from(new Set([value, ...savedTerms])).slice(0, 10))
    );
  }
}
