import classnames from 'classnames';
import * as React from 'react';
import styles from './styles/nativeSelect.scss';
import { NativeSelectProps } from './models';

/*
  Variable to track how many Selects were rendered to assign an unique ID
  WARNING: this is an autogenrated ID and at this moment its only useful for
  automated tests (end-to-end)
*/

let renderedItems = 0;

const NativeSelect = ({
  className = '',
  styled,
  isVertical,
  options,
  value,
  disabled = false,
  name,
  onChange,
  optionalLabelReference = '',
  labelName,
}: NativeSelectProps) => {
  const [counter] = React.useState(() => {
    renderedItems += 1;
    return renderedItems;
  });
  const wrapperClassname =
    optionalLabelReference === ''
      ? 'Sui-FitmentSelector--select-wrapper'
      : `Sui-FitmentSelector--select-wrapper Sui-FitmentSelector--select-wrapper-${optionalLabelReference}`;

  const hasSelected = labelName
    ? value
      ? {
          [styles.hasNotSelected]: false,
          'Sui-FitmentSelector--label_has_selected': true,
        }
      : {
          [styles.hasNotSelected]: true,
          'Sui-FitmentSelector--label_has_not_selected': true,
        }
    : {};

  return (
    <div
      className={classnames(`${wrapperClassname}`, {
        'Sui-FitmentSelector--select-wrapper-selected': value,
      })}
    >
      {labelName && (
        <label
          className={classnames(
            styles.fieldLabel,
            'Sui-FitmentSelector--label'
          )}
          htmlFor={`sui-fitment-selector-${counter}`}
        >
          {labelName}
        </label>
      )}
      <select
        id={`sui-fitment-selector-${counter}`}
        className={classnames(
          {
            [`${styles.styledSelect} SuiSelect`]: styled,
            [styles.styledSelectVertical]: styled && isVertical,
            ...hasSelected,
          },
          className,
          `sui-fitment-selector-${value}`
        )}
        disabled={disabled}
        value={value}
        onChange={onChange}
        role="listbox"
      >
        <option
          key={0}
          value=""
          role="option"
          aria-selected={false}
          className={classnames('Sui-FitmentSelector--initial-option')}
        >
          {labelName ? 'Select an option' : name}
        </option>
        <option key={-1} disabled={true} role="option" aria-selected={false}>
          ---
        </option>
        {options.map((data) => (
          <option
            id={String(data.id)}
            role="option"
            key={data.id}
            value={data.id}
            aria-selected={value === data.id}
            className={styles.optionItem}
          >
            {data.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default NativeSelect;
