import classnames from 'classnames';
import * as React from 'react';
import { FacetFilterItem } from '../../models/search';
import { FacetSectionCheckbox, FacetSectionProps } from './models';
import styles from './styles/facetSection.scss';

const SelectableItem = ({
  label,
  value,
  section,
  count: _,
  checked,
  styled,
  onChange,
  isSingleSelect,
}: FacetSectionCheckbox) => (
  <div
    className={classnames(
      styles.checkboxContainer,
      {
        [styles.styledCheckboxContainer]: styled,
      },
      'Sui-FacetSection-checkbox-container'
    )}
  >
    <div className={styles.left}>
      <input
        className={classnames(
          { [styles.styledCheckbox]: styled },
          'Sui-FacetSection-checkbox'
        )}
        type={isSingleSelect ? 'radio' : 'checkbox'}
        role={isSingleSelect ? 'radio' : 'checkbox'}
        id={`${section}-${value}`}
        name={String(value)}
        value={value}
        checked={checked}
        onChange={(e) => onChange(e.currentTarget.value)}
      ></input>
      <label
        className={classnames(
          styles.checkboxLabel,
          'Sui-FacetSection-checkbox-label'
        )}
        htmlFor={`${section}-${value}`}
      >
        {label}
      </label>
    </div>
  </div>
);

const FacetSection = ({
  className = '',
  values,
  section,
  selectedValues = [],
  onChange,
  limit = values.length,
  styled,
  isSingleSelect,
}: FacetSectionProps) => {
  const [showMore, setShowMore] = React.useState(false);
  const hasHiddenValues = values.length > limit;
  const valuesToShow = hasHiddenValues ? values.slice(0, limit) : values;
  const hiddenValues = hasHiddenValues ? values.slice(limit) : [];

  const renderSelectableItem = (filter: FacetFilterItem) => {
    return (
      <SelectableItem
        key={filter.id}
        label={String(filter.name)}
        value={String(filter.id)}
        section={section}
        checked={selectedValues.includes(String(filter.id))}
        onChange={onChange}
        styled={styled}
        isSingleSelect={isSingleSelect}
        count={filter.results}
      />
    );
  };
  const renderSelectedHiddenValues = () =>
    hiddenValues
      .filter((item) => selectedValues.includes(String(item)))
      .map((filter) => renderSelectableItem(filter));

  return (
    <div
      className={classnames(
        className,
        { [styles.sectionContainerStyled]: styled },
        'Sui-FacetSection--container'
      )}
    >
      {valuesToShow.map((filter) => renderSelectableItem(filter))}

      {!showMore && hasHiddenValues ? renderSelectedHiddenValues() : null}

      {showMore && hasHiddenValues
        ? hiddenValues.map((filter) => renderSelectableItem(filter))
        : null}
      {hasHiddenValues ? (
        <button
          className={styles.showMoreBtn}
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? 'Show less' : 'Show more...'}
        </button>
      ) : null}
    </div>
  );
};

export default FacetSection;
