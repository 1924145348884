/* eslint-disable max-len */
import * as React from 'react';

interface SearchIconLightProps {
  className?: string;
  size?: number;
}

const SearchIconLight = ({ size = 20, className }: SearchIconLightProps) => (
  <svg
    className={className}
    height={size}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    // style={"enable-background:new 0 0 100 100;"}
    xmlSpace="preserve"
  >
    <path d="M94.4,91.6L67.8,65c5.7-6.4,9.2-14.8,9.2-24C77,21.1,60.9,5,41,5S5,21.1,5,41s16.1,36,36,36c9.2,0,17.6-3.5,24-9.2  l26.6,26.6C92,94.8,92.5,95,93,95s1-0.2,1.4-0.6C95.2,93.6,95.2,92.4,94.4,91.6z M41,73C23.4,73,9,58.6,9,41C9,23.4,23.4,9,41,9  s32,14.4,32,32C73,58.6,58.6,73,41,73z" />
  </svg>
);

export default SearchIconLight;
