import * as React from 'react';
import {
  FitmentLabelEntity,
  FitmentSelectorProps,
} from '../FitmentSelector/models';
import { getFacetKeysFromUrl, FACET_PREFIX, SelectedFacetsType } from './utils';

export type SearchContextType = {
  clearFacetsOnSaveState: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ];
  selectedFacetState: [
    SelectedFacetsType,
    React.Dispatch<React.SetStateAction<SelectedFacetsType>>,
  ];
  facetKeysState: [
    Array<string>,
    React.Dispatch<React.SetStateAction<Array<string>>>,
  ];
  optionalLabelsDataState: [
    FitmentSelectorProps['optionalLabelsData'],
    React.Dispatch<
      React.SetStateAction<FitmentSelectorProps['optionalLabelsData']>
    >,
  ];
  optionalLabelsState: [
    FitmentLabelEntity[],
    React.Dispatch<React.SetStateAction<FitmentLabelEntity[]>>,
  ];
  qualifiersStatusState: [string, React.Dispatch<React.SetStateAction<string>>];
};

export const SearchPageContext = React.createContext<SearchContextType>(null);

interface SearPageProviderProps {
  children: React.ReactNode;
}

export const SearchPageProvider = ({ children }: SearPageProviderProps) => {
  const queryString = new URLSearchParams(window.location.search);
  const value = {
    clearFacetsOnSaveState: React.useState<boolean>(true),
    facetKeysState: React.useState<Array<string>>(null),
    optionalLabelsDataState: React.useState<
      FitmentSelectorProps['optionalLabelsData']
    >({}),
    optionalLabelsState: React.useState<FitmentLabelEntity[]>([]),
    qualifiersStatusState: React.useState<string>('disabled'),
    selectedFacetState: React.useState<SelectedFacetsType>(
      /*
        Here we set the intial selected facets based on what we have in the URL
        if there is nothing in the URL or if there are wrong facet keys,
        this value will be reseted when updateFacets function is executed, which will
        happen every time we load products (see onDataReceived callback in  WsmProductListWrapper component)
      */
      getFacetKeysFromUrl(queryString).reduce((acc, facet) => {
        const values = queryString.getAll(facet);
        if (values.length) {
          return {
            ...acc,
            [facet.replace(FACET_PREFIX, '')]: queryString.getAll(facet),
          };
        }
        return acc;
      }, {})
    ),
  };
  //   const selectedFacetState = React.useState<SelectedFacetsType>(null);
  return (
    <SearchPageContext.Provider value={value}>
      {children}
    </SearchPageContext.Provider>
  );
};
