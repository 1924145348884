import {
  OPTIONAL_QUALIFIERS_STORAGE_KEY,
  OPTIONAL_QUALIFIERS_FOR_URL_STORAGE_KEY,
} from '../../utils/constants';

export function getInitialQualifiers(
  queryString: URLSearchParams,
  isLocalStorage = false
) {
  const parsedVq =
    Object.keys(parseVehicleQualifierQuery(queryString)).length > 0
      ? parseVehicleQualifierQuery(queryString)
      : false;

  const onLocalstorage =
    JSON.parse(
      localStorage.getItem(OPTIONAL_QUALIFIERS_STORAGE_KEY) || '{}'
    )?.[0] || {};

  if (isLocalStorage) {
    return onLocalstorage;
  }

  return parsedVq || onLocalstorage;
}

export function getInitialQualifiersForURL(queryString: URLSearchParams) {
  const parsedVq =
    Object.keys(parseVehicleQualifierQuery(queryString)).length > 0
      ? parseVehicleQualifierQuery(queryString)
      : false;
  return parsedVq || buildVqUrlParameter();
}

export function parseVehicleQualifierQuery(queryString: URLSearchParams) {
  const vqs = {};
  queryString.forEach((value, key) => {
    // match only 'vq[' +  :alphanum: with _ + ']'
    if (key.match(/^vq\[[a-z_]+\]$/)) {
      vqs[key] = value;
    }
  });
  return vqs;
}

export function buildVqUrlParameter() {
  const vqs = {};
  const rawVq =
    JSON.parse(
      localStorage.getItem(OPTIONAL_QUALIFIERS_FOR_URL_STORAGE_KEY) || '{}'
    )?.[0] || {};

  Object.keys(rawVq)?.forEach((v) => {
    vqs[`vq[${v}]`] = rawVq[v];
  });

  return vqs;
}
