import { isEmpty } from 'lodash';
import {
  FitmentSelectorProps,
  FitmentLabelEntity,
  SelectedValues,
} from '../components/FitmentSelector/models';
import { FITMENT_SELECTOR_STORAGE_KEY } from './constants';

function validateSelectedValues(
  selectedValues: SelectedValues,
  labelsData: FitmentSelectorProps['labelsData'],
  labels: FitmentLabelEntity[]
) {
  let validSelectedValues: SelectedValues = {};

  for (const label of labels) {
    const labelId = (selectedValues || {})[label.name];
    if (!labelId || !labelsData[label.name]) break;
    const selectedValueExists = labelsData[label.name].find(
      (item) => String(item.id).toLowerCase() === String(labelId).toLowerCase()
    );
    if (!selectedValueExists) {
      break;
    }
    validSelectedValues = {
      ...validSelectedValues,
      [label.name]: selectedValues[label.name],
    };
  }

  return validSelectedValues;
}

export { validateSelectedValues };

export function convertSelectedDataToArray(
  labels: FitmentLabelEntity[],
  values: SelectedValues,
  labelValues: FitmentSelectorProps['labelsData']
) {
  const parsedValues = [];
  for (const label of labels) {
    if (values[label.name] && labelValues[label.name]) {
      const selectedValueName = labelValues[label.name].find(
        (item) =>
          String(item.id).toLowerCase() ===
          String(values[label.name]).toLowerCase()
      )?.name;

      parsedValues.push({
        key: label.name,
        value: selectedValueName as string | number,
      });
    }
  }

  return parsedValues;
}

export const updateFitmentQueryParams = (fitmentValue: any) => {
  const searchParams = new URLSearchParams(window.location.search);
  const url = window.location.pathname;

  if (isEmpty(fitmentValue)) {
    searchParams.delete('fitment');
    // window.location.search = searchParams.toString()
  } else {
    searchParams.set('fitment', Object.values(fitmentValue).join('|'));
  }
  const newUrl = searchParams.values
    ? `${url}?${searchParams.toString()}`
    : url;
  window.history.replaceState({}, '', newUrl);
};

export const getExistingFitment = () => {
  const urlFitment = new URLSearchParams(window.location.search);
  const storageFitment = localStorage.getItem(FITMENT_SELECTOR_STORAGE_KEY);
  let fitment = null;
  if (urlFitment.has('fitment')) {
    fitment = urlFitment.get('fitment');
    localStorage.setItem(FITMENT_SELECTOR_STORAGE_KEY, `["${fitment}"]`);
  } else if (storageFitment && storageFitment !== '[]') {
    fitment = JSON.parse(storageFitment)[0];
  }
  return fitment;
};

export const updateFacetQueryParams = (selectedFacets: any) => {
  const urlParams = new URLSearchParams(window.location.search);

  for (const facetName of Array.from(urlParams.keys())) {
    if (facetName.includes('facet-')) {
      urlParams.delete(facetName);
    }
  }

  for (const facetName in selectedFacets) {
    for (const facetValue of selectedFacets[facetName]) {
      urlParams.append(`facet-${facetName}`, facetValue);
    }
  }
  let newUrl = window.location.pathname;
  if (urlParams.size) {
    newUrl = `${newUrl}?${urlParams.toString()}`;
  }
  window.history.replaceState({}, '', newUrl);
};

export const getFacetsFromQueryParams = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const selectedFacets = {};
  queryParams.forEach((value, key) => {
    if (key.includes('facet-')) {
      selectedFacets[key.replace('facet-', '')] = value.split(',');
    }
  });
  return selectedFacets;
};
